<template>
    <div class="page1">
        <EForm
                :formColumns="searchFormColumns"
                :rowSize="4"
                :optionsBtn="true"
                :actionBtn="false"
                :formData="searchForm"
                ref="form"
                @onSearch="onSearch"
                :searchFlag="true"
                :exportShow="false"
        ></EForm>
        <EButton @click="dialogVisible=true">新增</EButton>
        <ETable :tableTitle="tableTitle" :tableData="tableData" :needPagination="false">
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton @click="editDialog(scope.row)" type="text">修改</EButton>
                        <el-button type="text " @click="deleteDept(scope.row)">删除
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="handleClose" @handleClick="saveData">
            <EForm :formColumns="formColumns"
                   :labelPosition="labelPosition"
                   :rowSize="1"
                   :optionsBtn="false"
                   :labelWidth="labelWidth"
                   :actionBtn="false"
                   :formData="form"
                   :formRules="formRules"
                   ref="form"
                   :searchFlag="false"
            ></EForm>
<!--            <el-form label-position="right" label-width="100px">-->
<!--                <el-form-item label="部门名称">-->
<!--                    <el-input placeHolder="最多可录入20字" maxlength="20" v-model="form.department_name	"></el-input>-->
<!--                </el-form-item>-->
<!--                <el-form-item>-->
<!--                    <el-button @click="handleClose">取消</el-button>-->
<!--                    <el-button type="primary" @click="addBtn">确定</el-button>-->
<!--                </el-form-item>-->
<!--            </el-form>-->
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  export default {
    name: 'Login',
    data() {
      return {
        tableTitle: [
          {
            label: '部门名称',
            prop: 'deptName',
          },
          {
            label: '部门全称',
            prop: 'fullName',
          },
        ],
        tableData: [],
        loading: false,
        dialogVisible: false,
        title: '添加部门',
        searchForm:{
          deptName:'',
          fullName:'',
        },
        form: {
          deptName:'',
          fullName:'',
          id:''
        },
        formRules:{
          deptName: [vxRule(true, '',"blur", "部门名称不能为空"),
            vxRule(true, val=>{
              return {result: val.length<=20, errMsg: "最多可录入20字"}})],
        },
        searchFormColumns:[
          {
            title:'部门名称',
            type:'text',
            property:'deptName',
            placeHolder:'最多可录入20字',
            require:true,
            show:true
          },
          {
            title:'部门全称',
            type:'text',
            property:'fullName',
            placeHolder:'最多可录入20字',
            require:true,
            show:true
          },
          {
            type:'actionBtn',
            show:false
          }
        ],
        formColumns:[{
          title:'部门名称',
          type:'text',
          property:'deptName',
          placeHolder:'最多可录入20字',
          require:true,
          show:true
        },
          {
            title:'部门全称',
            type:'text',
            property:'fullName',
            placeHolder:'最多可录入20字',
            require:true,
            show:true
          },
          {
            type:'actionBtn',
            show:false
          }
        ],
        labelPosition:'right',
        labelWidth:'100px'
      }
    },
    watch: {},
    created() {
      this.departmentListsFn()
    },
    components: {ETable, EDialog,EButton,EForm},
    beforeMount() {

    },
    methods: {
      onSearch(){
        this.departmentListsFn()
      },
      handleClose(){
        this.title='添加部门'
        this.dialogVisible=false
        this.form={
          deptName:'',
          fullName:'',
          id:''
        }
        this.$refs.form.$refs.form.resetFields()
      },
      async departmentListsFn() {
        let res = await Http.deptList(this.searchForm)
        this.tableData = res.data
      },
      saveData(){
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid && !this.error) {
            this.addBtn()
          } else {
            return false;
          }
        });
      },
      async addBtn() {
        let res = await Http.deptSubmit(this.form)
        if(res.code==200){
          this.$message.success(res.msg)
          this.handleClose()
          this.departmentListsFn()
        }

      },
      editDialog(data){
        this.title='编辑部门'
        this.form.deptName=data.deptName
        this.form.fullName=data.fullName
        this.form.id=data.id
        this.dialogVisible=true
      },
      deleteDept(data){
        this.$messageBox.confirm('确定删除该部门吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.removeDept(data.id)
        }).catch(res => {

        });
      },
      async removeDept(ids){
        let res = await Http.removeDept({ids})
        if(res.code==200){
          this.$message.success(res.msg)
          this.departmentListsFn()
        }

      }
    }
  }
</script>

<style lang="scss">

</style>

